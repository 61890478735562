import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { withRouter } from 'react-router'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { show, types, hide } from 'store/modals'
import {
  createOrderParty,
  getOrderParty,
  getOrderPartyStatus,
  leaveOrderParty,
  deleteOrderParty,
  detachParty,
} from 'store/order-party/order-party.actions'
import { pullStatus } from 'store/order-party/order-party.constants'
import {
  smartSelectOrderPartyId,
  selectOrderParty,
} from 'store/order-party/order-party.selectors'
import withOrderContainer from 'pages/order/modules/withOrderContainer'
import { loadMenu } from '../modules/menu'
import { loadVendor } from '../modules/info'
import { smartSelectOrderById } from 'pages/order/modules/order'
export default compose(
  withRouter,
  withOrderContainer({
    shouldIncludeFees: true,
  }),
  connect(
    (state, ownProps) => {
      const profile = get(state, 'profile.customer')

      const venueId = get(ownProps, 'match.params.id')

      const orderParty = get(state, 'orderParty')

      const selectedOrderParty = /\/order\/\:id\/review/.test(
        get(ownProps, 'match.path'),
      )
        ? selectOrderParty(state, venueId)
        : smartSelectOrderPartyId(state, venueId)

      const placedOrders = smartSelectOrderById(
        state,
        get(ownProps, 'match.params.id'),
      )

      const getOrder = () => {
        if (!get(selectedOrderParty, 'salesOrderId')) {
          return placedOrders
        }

        const salesOrderId = get(placedOrders, 'latestPlacedOrderId')
        return get(placedOrders, `placedOrders.${salesOrderId}`)
      }
      const orders = get(getOrder(), 'orders')

      const isHost =
        get(profile, 'id') === get(selectedOrderParty, 'leaderCustomerId')

      const memberProfile = get(profile, 'id')
        ? isHost
          ? {
              customerName: get(selectedOrderParty, `leaderCustomerName`),
              customerId: get(selectedOrderParty, `leaderCustomerId`),
              customerImage: get(selectedOrderParty, `leaderCustomerImage`),
              status: get(selectedOrderParty, 'leaderStatus'),
              order: get(selectedOrderParty, `order`),
              salesOrderId: get(selectedOrderParty, 'salesOrderId'),
              isPartyLeader: true,
            }
          : {
              ...get(selectedOrderParty, 'members', []).find(
                i => get(i, 'customerId') === get(profile, 'id'),
              ),
              ...(orders && { order: JSON.stringify(orders) }),
            }
        : null

      const memberList = isHost
        ? [
            {
              customerName: get(selectedOrderParty, `leaderCustomerName`),
              customerId: get(selectedOrderParty, `leaderCustomerId`),
              customerImage: get(selectedOrderParty, `leaderCustomerImage`),
              status: get(selectedOrderParty, `leaderStatus`),
              order: get(selectedOrderParty, `order`),
              status: get(selectedOrderParty, `leaderStatus`),
              salesOrderId: get(selectedOrderParty, 'salesOrderId'),
              isPartyLeader: true,
            },
            ...get(selectedOrderParty, 'members', []),
          ]
        : memberProfile
        ? [
            memberProfile,
            {
              customerName: get(selectedOrderParty, `leaderCustomerName`),
              customerId: get(selectedOrderParty, `leaderCustomerId`),
              customerImage: get(selectedOrderParty, `leaderCustomerImage`),
              order: get(selectedOrderParty, `order`),
              status: get(selectedOrderParty, `leaderStatus`),
              salesOrderId: get(selectedOrderParty, 'salesOrderId'),
              isPartyLeader: true,
            },
            ...get(selectedOrderParty, 'members', []).filter(
              i => get(i, 'customerId') !== get(profile, 'id'),
            ),
          ]
        : []

      return {
        isAuthenticated: get(state, 'auth.isAuthenticated', false),
        orderParty,
        profile,
        hasMenu: !isEmpty(get(state, 'vendor.menu.menus')),
        placedOrders,
        selectedOrderParty,
        isHost,
        memberList: memberProfile ? memberList : [],
      }
    },
    dispatch => ({
      showLoginModal: () => dispatch(show(types.LOGIN_V2)),
      showInviteModal: data => dispatch(show(types.PARTY_INVITE, data)),
      createOrderParty: ({ vendorId, inviteMessage = '', customerIds = [] }) =>
        dispatch(createOrderParty({ vendorId, inviteMessage, customerIds })),
      show,
      getOrderParty: ({ orderPartyId, orderPartyEnabled, source }) =>
        dispatch(getOrderParty({ orderPartyId, orderPartyEnabled, source })),
      leaveOrderParty: ({ orderPartyId, venueId, customerId }) =>
        dispatch(leaveOrderParty({ orderPartyId, venueId, customerId })),
      deleteOrderParty: ({ orderPartyId, venueId }) =>
        dispatch(deleteOrderParty({ orderPartyId, venueId })),
      loadMenu: (venueId, params) => dispatch(loadMenu(venueId, params)),
      loadVendor: venueId => dispatch(loadVendor(venueId)),
      getOrderPartyStatus: partyCode =>
        dispatch(getOrderPartyStatus({ partyCode })),
      showOrderPartyModal: params =>
        dispatch(show(types.ORDER_PARTY_GENERIC_MODAL, params)),
      hide: () => dispatch(hide()),
      detachParty: venueId => dispatch(detachParty(venueId)),
      showActiveOrderPartyModal: params =>
        dispatch(show(types.HAS_ACTIVE_ORDER_PARTY_MODAL, params)),
    }),
  ),
  connect(null, { pullStatus }),
  lifecycle({
    componentDidMount() {
      const {
        hasMenu,
        match: {
          params: { id: venueId },
          path,
        },
        loadVendor,
        loadMenu,
        selectedOrderParty,
        getOrderPartyStatus,
        detachParty,
      } = this.props
      const servicetype = 'takeaway'
      if (!hasMenu) {
        loadVendor(venueId)
        loadMenu(venueId, { servicetype })
      }

      if (
        /\/order\/:id\/review/.test(path) ||
        /\/order\/party\/:id\/status/.test(path)
      ) {
        getOrderPartyStatus(get(selectedOrderParty, 'partyCode')).catch(
          error => {
            const orderAddedStatuses = [
              'ORDER_PAID',
              'ORDER_SUBMITTED',
              'ORDER_PLACED',
            ]
            if (
              get(error, 'response.data.status.code') === 100 &&
              !orderAddedStatuses.includes(
                get(selectedOrderParty, 'leaderStatus'),
              )
            ) {
              detachParty(venueId)
            }
          },
        )
      }
    },
  }),
)
